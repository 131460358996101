<script>
import { ref } from "vue";
import { useTitle } from "@vueuse/core";
// import { useFetch } from "@vueuse/core";
export default {
  name: "Reception",
  setup() {
    const nameInput = ref("");
    const emailInput = ref("");
    const messageArea = ref("");
    const ress = ref("");
    const isMessageSuccess = ref(null);
    const title = useTitle("Интернет-приёмная");
    const sendEmail = async () => {
      const formData = new FormData();

      formData.append("your_name", nameInput.value);
      formData.append("your_email", emailInput.value);
      formData.append("your_message", messageArea.value);

      try {
        // const response =
        await fetch(
          "https://admin.dss-sport.ru/wp-json/contact-form-7/v1/contact-forms/1468/feedback",
          {
            method: "POST",
            body: formData,
          }
        );
        // const result = await response.json();
        // console.log("Успех:", JSON.stringify(result));
        nameInput.value = "";
        emailInput.value = "";
        messageArea.value = "";
        isMessageSuccess.value = true;
        new Promise(() => {
          setTimeout(() => {
            isMessageSuccess.value = false;
          }, 3500);
        });
      } catch (error) {
        console.error("Ошибка:", error);
      }
    };
    return {
      sendEmail,
      nameInput,
      emailInput,
      messageArea,
      ress,
      isMessageSuccess,
      title,
    };
  },
};
</script>

<template>
  <div class="reception reception-view">
    <div class="reception__wrapper">
      <h1 class="reception__wrapper--title">интернет-приёмная</h1>
      <div class="reception__wrapper--content">
        <p class="reception__wrapper--subtext">
          Оставьте своё сообщение или вопрос и вам ответят на указанный
          электронный адрес
        </p>
        <form
          action=""
          class="reception__wrapper--form"
          @submit.prevent="
            sendEmail({
              your_name: nameInput,
              your_email: emailInput,
              your_message: messageArea,
            })
          "
        >
          <input
            type="text"
            name="your-name"
            v-model="nameInput"
            placeholder="Ваше имя"
          />
          <input
            type="text"
            name="your-email"
            v-model="emailInput"
            placeholder="Ваша электронная почта"
          />
          <textarea
            name="your-message"
            id="message"
            cols="30"
            rows="10"
            v-model="messageArea"
            placeholder="Ваше сообщение"
          ></textarea>
          <input
            class="btn btn-primary"
            type="button"
            value="Отправить сообщение"
            @click="sendEmail()"
          />
        </form>
        <div class="message__block" :class="{ isShow: isMessageSuccess }">
          <span>Сообщение успешно отправленно</span>
          <span class="message__block--close" @click="isMessageSuccess = false"
            >x</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.reception
  max-width: $default-padding*51.5
  margin: 0 auto 0 $default-padding*7.5
  @media (max-width: $sizes-exl + $default-padding*10)
    margin: auto
  &__wrapper
    position: relative
    padding: $default-padding*2.8 0
    &--title
      font-size: $base-font-size*3
      font-weight: 800
      line-height: $base-font-size*3.4
      letter-spacing: 0.05em
      text-transform: uppercase
      max-width: $default-padding*40.6
      margin-bottom: $default-padding*2
    &--subtext
      font-size: $default-padding*1.2
    &--form
      @include d-flex(row, space-between, flex-start, null, null, $default-padding)
      flex-wrap: wrap
      margin-top: $default-padding*2.8
      & input
        &::placeholder
          color: #5c5c5c
      & input[type="text"]
        width: 100%
        flex: 1
        padding: $default-padding
        border-radius: 10px
        border: 1px solid #d4d4d4
        @media only screen and (max-width: $sizes-md)
          flex: auto
      & textarea
        width: 100%
        padding: $default-padding
        border-radius: 10px
        border: 1px solid #d4d4d4
        &::placeholder
          color: #5c5c5c
      & input[type="button"]
        margin: auto

.message__block
  position: relative
  opacity: 0
  padding: 1em 1.5em
  margin-top: 1em
  background-color: #e5f9e7
  border-radius: 10px
  box-shadow: 0 0 0 1px #1ebc30 inset, 0 0 0 0 transparent
  color: #1ebc30
  transition: opacity .3s ease-in-out
  transition-delay: .2s
  &.isShow
    opacity: 1
  &--close
    position: absolute
    cursor: pointer
    top: 5px
    right: 10px
    font-size: 18px
</style>
